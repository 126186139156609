import React from "react";

import AboutImage from "../assets/images/about.jpg";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function About() {
  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div className="relative">
                <img src={AboutImage} className="rounded-lg shadow-lg relative" alt="Thierry and farm manager in front of plantations" />
              </div>
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-green-600 text-base font-medium uppercase mb-2">Who We Are ?</h6>
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Our Company Story</h3>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">Start partnering with Ragati & Wangari Farm, a sustainable herb farm dedicated to community impact and eco-friendly practices. Established in 2023, the farm employs local villagers, empowering women and creating steady jobs. With its own greenhouses, borehole, and solar-powered operations, Ragati & Wangari Farm ensures fresh, responsibly-grown herbs while supporting regional development.</p>

                <div className="relative mt-10">
                  <a href="#plants" className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md">View our Herbs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
    </>
  );

}

