import React from "react";
import { Link } from "react-scroll";

import Typed from "typed.js";

import BackgroudImage from "../assets/images/bg/1.jpg";
import Services from "../component/Services";
import About from "../component/About";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Plants from "../component/Plants";
import Switcher from "../component/Switcher";
import Navbar from "../component/navbar";

/**
 * Index Component
 */
export default function Index() {
  setTimeout(() => {
    new Typed("#typed", {
      strings: [
        "<b>Chives</b>",
        "<b>Tarragon</b>",
        "<b>Parsley</b>",
        "<b>Mint</b>",
        "<b>Rosemary</b>"
      ],
      backDelay: 2000,
      loop: true,
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
    });
  }, 500);

  return (
    <>
      <div>
        <Navbar/>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12">
              <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                Freshly Harvested{" "}
                <span
                  className="typewrite relative text-type-element"
                  id="typed"
                  data-period="2000"
                  data-type='[ "Business", "Startups", "Digital Agency", "Marketing" ]'
                ></span><br/>
                Ready for export
              </h4>

              <p className="text-white opacity-70 mb-0 max-w-2xl text-lg">
                Support local growth and sustainability with our farm, creating jobs in the region and cultivating high-quality herbs for export worldwide.
              </p>

              <div className="relative mt-10">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md hover:cursor-pointer"
                >
                  Talk to us
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* About section */}
        <About />

        {/* Service section */}
        <Services />

        {/* Plants section */}
        <Plants />

        {/* Contact section */}
        <Contact />

        {/* Footer section */}
        <Footer />

        {/* Switcher section */}
        <Switcher />
      </div>

    </>
  );

}

