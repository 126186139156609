import React from "react";

import WeGrowImage from "../assets/images/services/farmer.jpg";
import PackagedVegImage from "../assets/images/services/packaged.jpg";
import PlaneImage from "../assets/images/services/plane.jpg";

export default function Services() {
  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-green-600 text-base font-medium uppercase mb-2">What We Do?</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Sustainable Practices for Premium Herbs</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Our farm manages every step in-house, from cultivation to harvest, using sustainable methods to deliver fresh, export-quality herbs grown with care.</p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={WeGrowImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">We grow</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Our herbs are cultivated using sustainable methods to ensure quality and freshness from the very start.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">We store and package</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Harvested herbs are kept in our cool room to preserve their freshness, then carefully packaged for export.</p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={PackagedVegImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={PlaneImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">We ship</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">We delegate the logistics to deliver our herbs efficiently, ensuring they arrive fresh at their final destination.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}
