import React, { useState } from "react";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { Link } from "react-scroll";

import ThymeImage from "../assets/images/portfolio/thyme.jpg";
import RosemaryImage from "../assets/images/portfolio/rosemary.jpg";
import ChivesImage from "../assets/images/portfolio/chives.jpg";
import TarragonImage from "../assets/images/portfolio/tarragon.jpg";
import BasilImage from "../assets/images/portfolio/basil.jpg";
import SorrelImage from "../assets/images/portfolio/sorrel.jpg";
import ChervilImage from "../assets/images/portfolio/chervil.jpg";
import OthersImage from "../assets/images/portfolio/others.jpg";

import CTABackground from "../assets/images/bg/cta.jpg";

const images = [
    ThymeImage,
    RosemaryImage,
    ChivesImage,
    TarragonImage,
    BasilImage,
    SorrelImage,
    ChervilImage,
    OthersImage
];

/**
 * Plants section
 */
export default function Plants() {
    const [photoIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);

    const projectList = [
        {

            image: ThymeImage,
            title: 'Thyme',
            subtext: '100kg/week'
        },
        {
            image: RosemaryImage,
            title: 'Rosemary',
            subtext: '100kg/week'
        }, {
            image: ChivesImage,
            title: 'Chives',
            subtext: '200kg/week'
        }, {
            image: TarragonImage,
            title: 'Tarragon',
            subtext: '25kg/week'
        }, {
            image: BasilImage,
            title: 'Basil',
            subtext: '150kg/week'
        }, {
            image: SorrelImage,
            title: 'Sorrel',
            subtext: 'On-demand'
        },
        {
            image: ChervilImage,
            title: 'Chervil',
            subtext: 'On-demand'
        },
        {
            image: OthersImage,
            title: 'Others',
            subtext: 'On-demand'
        }
    ]
    return (
        <>
            {/* Project Start  */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 active" id="plants">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-green-600 text-base font-medium uppercase mb-2">Herbs</h6>
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Our Herbs &amp; More</h3>

                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Explore our premium herbs, mostly grown in-house with care. Looking for something specific? We can source additional herbs on demand.</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
                        {projectList.map((item, index) => (
                            <div className="relative rounded-md shadow-sm overflow-hidden group" key={index}>
                                <img src={item.image} className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500" alt="workimage" />
                                <div className="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                                <div className="content">
                                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                        <p className="h6 text-md font-medium text-green-600 transition duration-500">{item.title}</p>
                                        <p className="text-slate-100 tag mb-0">{item.subtext}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => setOpen({ isOpen: false })}
                            onMovePrevRequest={() =>
                                setActiveIndex((photoIndex + images.length - 1) % images.length,
                                )
                            }
                            onMoveNextRequest={() =>
                                setActiveIndex((photoIndex + 1) % images.length,
                                )
                            }
                        />
                    )}
                </div>
            </section>
            {/* Project End  */}
            <section
                style={{ backgroundImage: `url(${CTABackground})` }}
                className="py-24 w-full table relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">Ready to choose sustainability?</h3>

                        <p className="text-white opacity-50 max-w-xl mx-auto">Get in touch with us and get a quote for your need.</p>

                        <div className="relative mt-10">
                            <Link
                                to="contact"
                                spy={true}
                                smooth={true}
                                duration={500}
                                className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md hover:cursor-pointer"
                            >
                                Talk to us
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
